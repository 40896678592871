#languageChoice #content {
  h1 {
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      margin-bottom: .5em;
    }
  }
  .intro {
    font-size: rem(15);
    min-height: 90px;
    @include media-breakpoint-up(md) {
      min-height: 120px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 100px;
    }
  }
  .card {
    .card-header:after {
      margin-right: 1rem;
    }
    .card-text {
      font-size: rem(13);
      margin-top: .5rem;
    }
  }
  ul {
    li {
      &.company .card {
        @include media-breakpoint-up(md) {
          min-height: 220px;
        }
        @include media-breakpoint-up(lg) {
          min-height: 165px;
        }
      }
    }
  }
  .btn {
    border-radius: 0;
    border-bottom-right-radius: $border-radius;
    padding: .5rem .75rem .5rem;
    margin-top: .5em;
    margin-bottom: .5em;
    &:after {
      @include faw-icon($fa-var-chevron-down,12);
      position: absolute;
      top: calc(50% + 1px);
      left: auto;
      right: 1rem;
      transform: translateY(-50%);
      background: none;
    }
    &[aria-expanded="true"] {
      &:after {
        @include faw-icon($fa-var-chevron-up,12);
      }
    }
  }
}