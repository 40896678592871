header {
  .logo {
    font-family: $font-family-primary;
    font-size: 1.625em;
  }
  #date {
    display: inline-block;
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}
.errorPage {
  #content {
    .col-12 + .col-12 {
      .card {
        border-top: 1px solid $border-color;
      }
      @include media-breakpoint-up(md) {
        border-left: 1px solid $border-color;
        .card {
          border: 0;
        }
      }
    }
  }
}
#contentWrapper {
  .card {
    border: 0;
  }
  .btn {
    border: 0;
    padding: 0;
    @include media-breakpoint-down(lg) {
      &[data-toggle="collapse"] {
        @include collapse-icon(1em);
        padding-left: 1.5em;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .nav-link {
    background-color: $light;
    color: $body-color;
    border-bottom: 1px solid $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: $card-border-width solid $card-border-color;
    &:after {
      @include faw-icon($fa-var-chevron-right,12);
      margin-top: 1px;
      margin-left: 10px;
    }
    @include media-breakpoint-up(md) {
      display: inline-block;
      background-color: transparent;
      border: 0;
      font-size: $h3-font-size;
      color: $link-color;
      text-decoration: underline;
      &:after {
        content: none;
      }
    }
  }
}